function Campaign() {
	return {
		id: null,
		name: null,
		external_campaign_id: null,
		external_call_type: null,
		views: [],
		ads: [],
		audiences: [],
		created_at: null,
		updated_at: null
	}
}

export default Campaign;