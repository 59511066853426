<template>
  <div class="campaigns">
    <loading :active="loading" />

    <modal
      :active="createNewCampaignActive"
      @dismiss="createNewCampaignActive = false"
      class="create-campaign-modal form-control"
    >
      <template v-if="newCampaign">
        <h2>Create New Campaign</h2>
        <label>Name</label>
        <input v-model="newCampaign.name" type="text" />

        <label>External Call Type</label>
        <input v-model="newCampaign.external_call_type" type="text" />

        <label>External Campaign ID</label>
        <input v-model="newCampaign.external_campaign_id" type="text" />

        <label>Audience</label>
        <select v-model="newCampaign.audiences" multiple>
          <option
            v-for="(audience, index) in audiences"
            :key="`audience-${index}`"
            :value="audience.id"
          >
            {{ audience.name }}
          </option>
        </select>

        <button class="button" @click="onCreate">Create</button>
      </template>
    </modal>

    <div class="hero hero--engage">
      <div class="container">
        <template v-if="campaigns">
          <h2>Campaigns</h2>
          <button class="button" @click="onNewCampaign">New Campaign</button>
        </template>
      </div>
    </div>

    <div class="container">
      <div class="campaign-wrapper">
        <div
          v-for="campaign in campaigns"
          :key="`campaign-${campaign.id}`"
          class="campaign"
        >
          <ActionCard
            :heading="campaign.name"
            :icon="getIcon(campaign)"
            :color="getIconColor(campaign)"
            :actions="[
              {
                label: 'Report',
                icon: 'chart',
                onClick: () => {
                  $router.push(`campaigns/${campaign.id}/report`);
                },
              },
              {
                label: 'Overview',
                icon: 'campaign',
                onClick: () => {
                  $router.push(`campaigns/${campaign.id}/overview`);
                },
              },
              {
                label: 'Edit',
                icon: 'edit',
                onClick: () => {
                  $router.push(`campaigns/${campaign.id}`);
                },
              },
            ]"
          >
            <Pill
              :count="campaign.status"
              :label="true"
              :alert="campaign.status == 'inactive'"
            />
          </ActionCard>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Loading from "@/components/Loading";
import Modal from "@/components/Modal";
import Pill from "@/components/Pill";
import { content } from "@/mixins/content";
import { colors } from "@/mixins/colors";
import Campaign from "@/models/Campaign";
import ActionCard from "@/components/ActionCard";

export default {
  name: "Campaigns",
  components: {
    Loading,
    Modal,
    Pill,
    ActionCard,
  },
  mixins: [content, colors],
  data() {
    return {
      createNewCampaignActive: false,
      newCampaign: null,
    };
  },
  methods: {
    onNewCampaign() {
      this.newCampaign = new Campaign();
      this.createNewCampaignActive = true;
    },
    onCreate() {
      this.$store
        .dispatch("engage/addCampaign", this.newCampaign)
        .then((res) => {
          if (res.data) {
            this.$router.push({
              path: `campaigns/${res.data.id}`,
            });
          }
        });
    },
    getIcon(campaign) {
      if (campaign.audiences && campaign.audiences.length) {
        return campaign.audiences[0].specialty;
      }
    },
    getIconColor(campaign) {
      if (campaign.audiences && campaign.audiences.length) {
        return this.getColorBySpecialty(campaign.audiences[0].specialty);
      }
    },
  },
  mounted() {
    if (!this.campaigns.length) {
      this.$store.dispatch("identification/getAudiences");
      this.$store.dispatch("engage/getCampaigns");
    }

    this.newCampaign = null;
  },
};
</script>

<style lang="scss">
@import "@/scss/colors";
@import "@/scss/button";
@import "@/scss/forms";
@import "@/scss/hero";

.campaigns {
  .hero {
    .container {
      height: calc(100% - 8rem);
      display: flex;
      align-items: center;
      color: #fff;

      h2 {
        margin-bottom: 0 !important;
        font-size: 1.5rem !important;
        font-weight: 600;
      }

      .button {
        margin-left: auto;
      }
    }

    & + .container {
      margin-top: -8rem;
    }
  }

  .campaign-wrapper {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -1rem;

    .campaign {
      width: 33.3333%;
      padding: 0 1rem 2rem 1rem;

      .card {
        h3 {
          font-size: 1rem;
        }
        h3 + .pill {
          margin-top: 0.5rem;
        }
        &.with-footer {
          position: relative;
          padding-bottom: calc(2rem + 64px) !important;

          header {
            display: flex;
            align-items: center;

            .icon {
              margin-left: auto;
              width: 40px;
              height: 40px;
              background-color: $disabled;
              border-radius: 50%;
              display: flex;
              align-items: center;
              justify-content: center;

              img {
                width: 24px;
              }
            }
          }

          footer {
            border-top: solid 2px $fade-gray;
            position: absolute;
            width: 100%;
            bottom: 0;
            left: 0;
            height: 64px;
            display: flex;

            .cell {
              border-right: solid 2px $fade-gray;
              display: flex;
              align-items: center;
              justify-content: center;
              font-weight: 600;
              font-size: 0.875rem;
              width: 33.333%;

              svg {
                width: 18px;
                height: 18px;
                fill: $green;
                margin-right: 0.5rem;
                margin-top: -4px;
              }

              &:last-of-type {
                border-right: 0;
              }
            }
          }
        }
      }
    }
  }
}
</style>